<template>
  <div class="content">
    <h1 class="title title--theme title--indent">Добавление службы</h1>
    <form @submit.prevent="onCheckForm">
      <app-grid>
        <template #item-1>
          <h2 class="title title--medium title--indent">Основные сведения</h2>
          <app-form-group label="Категория" required>
            <v-select
              :options="category_options"
              :filterable="false"
              :clearable="false"
              :searchable="false"
              v-model="form.direction_category"
              @input="onChangeCategory"
              label="name"
              placeholder="Выберите категорию"
              class="select select--minw"
            >
              <template #open-indicator>
                <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                  <path d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z" fill="#fff"></path>
                </svg>
              </template>
              <template #option="option">
                <div class="select__item d-center">{{ option.name }}</div>
              </template>
              <template #selected-option="option">
                <div class="selected d-center">{{ option.name }}</div>
              </template>
              <template #no-options>Ничего не найдено</template>
            </v-select>
            <template #error>
              <div v-if="$v.form.direction_category.$dirty && !$v.form.direction_category.required">
                Обязательное поле
              </div>
            </template>
          </app-form-group>
          <app-form-group v-if="form.direction_category.has_service" required label="Служба">
            <v-select
              v-model="form.service"
              :reduce="item => item.id"
              :options="service_options"
              :filterable="false"
              :clearable="false"
              :searchable="false"
              label="name"
              placeholder="Выберите значение"
              class="select"
              :class="{ 'select--error': $v.form.service.$error }"
            >
              <template #open-indicator>
                <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                  <path
                    d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z"
                    fill="#fff"></path>
                </svg>
              </template>
              <template #option="option">
                <div class="select__item d-center">{{ option.name }}</div>
              </template>
              <template #selected-option="option">
                <div class="selected d-center">{{ option.name }}</div>
              </template>
              <template #no-options>Ничего не найдено</template>
            </v-select>
            <template #error>
              <div v-if="$v.form.service.$dirty && !$v.form.service.required">
                Обязательное поле
              </div>
            </template>
          </app-form-group>
<!--          <app-form-group label="Уровень доступа" required>-->
<!--            <v-select-->
<!--              :reduce="item => item.id"-->
<!--              :options="passcode_options"-->
<!--              :filterable="false"-->
<!--              :clearable="false"-->
<!--              :searchable="false"-->
<!--              v-model="form.passcode"-->
<!--              label="name"-->
<!--              placeholder="Выберите уровень доступа"-->
<!--              class="select select&#45;&#45;minw"-->
<!--            >-->
<!--              <template #open-indicator>-->
<!--                <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">-->
<!--                  <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>-->
<!--                  <path d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z" fill="#fff"></path>-->
<!--                </svg>-->
<!--              </template>-->
<!--              <template #option="option">-->
<!--                <div class="select__item d-center">{{ option.name }}</div>-->
<!--              </template>-->
<!--              <template #selected-option="option">-->
<!--                <div class="selected d-center">{{ option.name }}</div>-->
<!--              </template>-->
<!--              <template #no-options>Ничего не найдено</template>-->
<!--            </v-select>-->
<!--            <template #error>-->
<!--              <div v-if="$v.form.passcode.$dirty && !$v.form.passcode.required">-->
<!--                Обязательное поле-->
<!--              </div>-->
<!--            </template>-->
<!--          </app-form-group>-->
          <app-form-group required label="Фамилия" label-for="last_name">
            <app-input
              v-model="form.last_name"
              id="last_name"
              placeholder="Введите фамилию"
              :error="$v.form.last_name.$error"
              @change.native="$v.form.last_name.$touch()"
            />
            <template #error>
              <div v-if="$v.form.last_name.$dirty && !$v.form.last_name.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group required label="Имя" label-for="first_name">
            <app-input
              v-model="form.first_name"
              id="first_name"
              placeholder="Введите имя"
              :error="$v.form.first_name.$error"
              @change.native="$v.form.first_name.$touch()"
            />
            <template #error>
              <div v-if="$v.form.first_name.$dirty && !$v.form.first_name.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group label="Отчество" label-for="patronymic">
            <app-input
              v-model="form.patronymic"
              id="patronymic"
              placeholder="Введите отчество"
            />
          </app-form-group>
          <app-form-group>
            <app-cropper-web-cam
              v-model="form.crop"
              :aspectRatio="3/4"
              ref="cropper"
            />
            <template #error>
              <div v-if="$v.form.crop.$dirty && !$v.form.crop.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group required label-for="birthday" label="Дата рождения">
            <app-input
              v-model="form.birthday"
              v-mask="'99.99.9999'"
              id="birthday"
              placeholder="дд.мм.гггг"
              autocomplete="off"
              :error="$v.form.birthday.$error || $v.birthday_date.$invalid"
              @paste.native.prevent
              @input.native="onCheckBirthdayDate"
            />
            <template #error>
              <div v-if="$v.form.birthday.$dirty && !$v.form.birthday.underscorePresent">Заполните поле
                полностью
              </div>
              <div v-if="$v.form.birthday.$dirty && !$v.form.birthday.required">Обязательное поле</div>
              <div v-if="$v.form.birthday.$dirty && !$v.birthday_date.between">Некорректная дата</div>
            </template>
          </app-form-group>
          <app-form-group required label="Пол">
            <app-cells position="start" :indent="false">
              <app-radio
                v-for="gender in gender_options"
                :key="gender.id"
                v-model.number="form.gender"
                :value="gender.id"
                :label="gender.name"
                name="gender"
              />
            </app-cells>
            <template #error>
              <div v-if="$v.form.gender.$dirty && !$v.form.gender.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group required label="Адрес электронной почты" label-for="email">
            <app-input
              v-model="form.email"
              id="email"
              type="email"
              placeholder="Введите адрес электронной почты"
              :error="$v.form.email.$error"
              @change.native="$v.form.email.$touch()"
            />
            <template #error>
              <div v-if="$v.form.email.$dirty && !$v.form.email.email">Некорректный формат электронной почты</div>
              <div v-if="$v.form.email.$dirty && !$v.form.email.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group label="Телефон" required>
            <app-phone
              v-model="form.phone"
              :error="$v.form.phone.$dirty && (!$v.form.phone.required || (form.phone === 0))"
              @change.native="$v.form.phone.$touch()"
              @paste.native.prevent
            />
            <template #error>
              <div v-if="$v.form.phone.$dirty && !$v.form.phone.required">Обязательное поле</div>
              <div v-if="$v.form.phone.$dirty && (form.phone === 0) && $v.form.phone.required">
                Неправильный формат номера
              </div>
            </template>
          </app-form-group>
          <app-form-group label='Ссылка на личный аккаунт "ВКонтакте"' label-for="vk">
            <app-input
              v-model="form.vk_link"
              id="vk"
              type="text"
              placeholder="Введите ссылку"
            />
          </app-form-group>
        </template>
        <template #item-2>
          <h2 class="title title--medium title--indent">Занятость</h2>
          <app-form-group label="Тип занятости">
            <app-cells position="start" :indent="false">
              <app-radio
                v-for="occ in occupation_options"
                :key="occ.id"
                v-model.number="form.occupation"
                :label="occ.name"
                :value="occ.id"
                name="occupation"
                @change="onOccupationChange"
              />
            </app-cells>
          </app-form-group>
          <app-form-group v-if="form.occupation === 1" label="Название образовательной организации" >
            <v-select
              v-model="form.organization_json"
              :options="edu_options"
              :filterable="false"
              :clearable="false"
              @search="onEDUSearch"
              label="name"
              placeholder="Введите значение"
              class="select"
            >
              <template #open-indicator>
                <svg class="select__open-indicator" xmlns="http://www.w3.org/2000/svg" width="23" height="23"
                     fill="none">
                  <path clip-rule="evenodd" d="M10.06 17.25a7.19 7.19 0 100-14.38 7.19 7.19 0 000 14.38z"
                        stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path d="M20.12 20.12l-4.98-4.98" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                </svg>
              </template>
              <template #no-options="{ search, searching }">
                <div v-if="searching" class="select__noty">
                  По вашему запросу <em>{{ search }}</em> ничего не найдено.
                  <br>
                  Попробуйте еще раз.
                </div>
                <div v-else class="select__noty">
                  Для поиска образовательной организации в справочнике, введите наименование в формате: <span>МБОУ СОШ 1 Ставрополь</span>
                  или <span>Ставропольский ГАУ</span> или ИНН организации и выберите значение из выпадающего
                  списка.
                </div>
              </template>
              <template #option="option">
                <div class="select__item d-center">{{ option.name }} ({{ option.address }})</div>
              </template>
              <template #selected-option="option">
                <div class="selected d-center">{{ option.name }} ({{ option.address }})</div>
              </template>
            </v-select>
            <div
              v-if="form.organization_json && form.organization_json.name.length > 20"
              class="select__long"
            >
              {{ form.organization_json && form.organization_json.name }} ({{ form.organization_json.address }})
            </div>
          </app-form-group>
          <template v-if="form.occupation === 2">
            <app-form-group label="Место работы">
              <v-select
                v-model="form.organization_json"
                :options="org_options"
                :filterable="false"
                :clearable="false"
                @search="onOrgSearch"
                label="name"
                placeholder="Введите значение"
                class="select"
              >
                <template #open-indicator>
                  <svg class="select__open-indicator" xmlns="http://www.w3.org/2000/svg" width="23" height="23"
                       fill="none">
                    <path clip-rule="evenodd" d="M10.06 17.25a7.19 7.19 0 100-14.38 7.19 7.19 0 000 14.38z"
                          stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M20.12 20.12l-4.98-4.98" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                  </svg>
                </template>
                <template #no-options="{ search, searching }">
                  <div v-if="searching" class="select__noty">
                    По вашему запросу <em>{{ search }}</em> ничего не найдено.
                    <br>
                    Попробуйте еще раз.
                  </div>
                  <div v-else class="select__noty">
                    Для поиска организации в справочнике, введите наименование в формате: <span>Администрация ленинского района города Ставрополя</span>
                    или ИНН <span>2634055412</span> организации и выберите значение из выпадающего списка.
                  </div>
                </template>
                <template #option="option">
                  <div class="select__item d-center">{{ option.name }} ({{ option.address }})</div>
                </template>
                <template #selected-option="option">
                  <div class="selected d-center">{{ option.name }} ({{ option.address }})</div>
                </template>
              </v-select>
              <div
                v-if="form.organization_json && form.organization_json.name.length > 20"
                class="select__long"
              >
                {{ form.organization_json && form.organization_json.name }} ({{ form.organization_json.address }})
              </div>
            </app-form-group>
            <app-form-group label="Должность" label-for="position">
              <app-input
                v-model="form.position"
                id="position"
                placeholder="Укажите занимаемую должность"
              />
            </app-form-group>
          </template>
          <app-form-group v-if="form.occupation === 3" label="Укажите тип занятости, в случае отсутствия поставьте -" label-for="position">
            <app-input
              v-model="form.position"
              id="position"
              placeholder="Введите значение"
            />
          </app-form-group>
          <h2 class="title title--medium title--indent">Дополнительные сведения</h2>
          <app-form-group required label="Регион проживания">
            <v-select
              v-model="form.region_residence"
              :reduce="item => item.id"
              :options="region_options"
              :filterable="true"
              :clearable="false"
              :searchable="true"
              label="name"
              placeholder="Выберите значение"
              class="select"
              :class="{ 'select--error': $v.form.region_residence.$error }"
            >
              <template #open-indicator>
                <svg width="18" height="18" fill="none" class="open-indicator"
                     xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                  <path
                    d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z"
                    fill="#fff"></path>
                </svg>
              </template>
              <template #option="option">
                <div class="select__item d-center">{{ option.name }}</div>
              </template>
              <template #selected-option="option">
                <div class="selected d-center">{{ option.name }}</div>
              </template>
              <template #no-options>Ничего не найдено</template>
            </v-select>
            <template #error>
              <div v-if="$v.form.region_residence.$dirty && !$v.form.region_residence.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group required label="Тип питания">
            <v-select
              v-model="form.food_type"
              :reduce="item => item.id"
              :options="meal_options"
              :filterable="false"
              :clearable="false"
              :searchable="false"
              label="name"
              placeholder="Выберите значение"
              class="select"
              :class="{ 'select--error': $v.form.food_type.$error }"
            >
              <template #open-indicator>
                <svg width="18" height="18" fill="none" class="open-indicator"
                     xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                  <path
                    d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z"
                    fill="#fff"></path>
                </svg>
              </template>
              <template #option="option">
                <div class="select__item d-center">{{ option.name }}</div>
              </template>
              <template #selected-option="option">
                <div class="selected d-center">{{ option.name }}</div>
              </template>
              <template #no-options>Ничего не найдено</template>
            </v-select>
            <template #error>
              <div v-if="$v.form.food_type.$dirty && !$v.form.food_type.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group required label="Размер одежды">
            <v-select
              v-model="form.clothingsize"
              :reduce="item => item.id"
              :options="clothingsize_options"
              :filterable="false"
              :clearable="false"
              :searchable="false"
              label="name"
              placeholder="Выберите значение"
              class="select"
              :class="{ 'select--error': $v.form.clothingsize.$error }"
            >
              <template #open-indicator>
                <svg width="18" height="18" fill="none" class="open-indicator"
                     xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                  <path
                    d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z"
                    fill="#fff"></path>
                </svg>
              </template>
              <template #option="option">
                <div class="select__item d-center">{{ option.name }}</div>
              </template>
              <template #selected-option="option">
                <div class="selected d-center">{{ option.name }}</div>
              </template>
              <template #no-options>Ничего не найдено</template>
            </v-select>
            <template #error>
              <div v-if="$v.form.clothingsize.$dirty && !$v.form.clothingsize.required">Обязательное поле</div>
            </template>
          </app-form-group>
        </template>
      </app-grid>
      <app-cells>
        <app-button ref="submit" :disabled="$v.form.$error || (form.phone === 0)">Добавить службу</app-button>
      </app-cells>
    </form>
  </div>
</template>

<script>
import { dateFormatting } from '@/helpers'
import { differenceInYears, parse } from 'date-fns'
import { debounce } from 'lodash'
import {
  getClothingSize,
  getDirectoratesCategories,
  getDirectoratesServices,
  getEduOrg,
  getGender,
  getMealType,
  getOccupation,
  getOrg,
  getRegions,
  postDirectorate,
  getSecurityCategories
} from '@/http'
import { between, email, required } from 'vuelidate/lib/validators'

const underscorePresent = value => {
  if (!value) return true
  else return !value.includes('_')
}

export default {
  name: 'DirectorateCreate',
  data() {
    return {
      form: {
        organization_json: {},
        direction_category: '',
        position: '',
        passcode: 1,
      },
      directorate_name: '',
      birthday_date: 25,
      service_options: [],
      gender_options: [],
      meal_options: [],
      clothingsize_options: [],
      region_options: [],
      occupation_options: [],
      org_options: [],
      edu_options: [],
      category_options: [],
      passcode_options: [],
    }
  },
  validations() {
    const form = {
      last_name: { required },
      first_name: { required },
      crop: { required },
      direction_category: { required },
      gender: { required },
      email: { required, email },
      phone: { required },
      food_type: { required },
      clothingsize: { required },
      region_residence: { required },
      passcode: { required },
      birthday: { required, underscorePresent },
      service: {},
    }
    if (this.form.direction_category.has_service) form.service = { required }

    return {
      form,
      birthday_date: {between: between(0, 90)},
    }
  },
  created() {
    getDirectoratesCategories()
      .then(response => {
        this.category_options = response.data
      })
    getGender()
      .then(response => {
        this.gender_options = response.data
      })
    getMealType()
      .then(response => {
        this.meal_options = response.data
      })
    getClothingSize()
      .then(response => {
        this.clothingsize_options = response.data
      })
    getRegions()
      .then(response => {
        this.region_options = response.data
      })
    getOccupation()
      .then(response => {
        this.occupation_options = response.data
      })
    getSecurityCategories()
      .then(response => {
        this.passcode_options = response.data
        this.form.passcode = response.data[0].id || 41
      })
  },
  methods: {
    onChangeCategory(category) {
      this.form.service = ''
      if (category.has_service) {
        getDirectoratesServices(category.id)
          .then(response => {
            this.service_options = response.data
          })
      }
    },
    onCheckForm() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание!',
          text: 'Проверьте правильность заполнения полей формы.'
        })
      } else {
        this.$refs.cropper.sendCrop().then(() => {
          this.form.photo_id = this.form.crop.photo
          this.form.coords = this.form.crop.coords
          this.sendForm()
        })
      }
    },
    sendForm() {
      this.$refs.submit.preload = true
      postDirectorate(this.normalizeForm())
        .finally(() => {
          this.$refs.submit.preload = false
        })
        .then(() => {
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Служба добавлена'
          })
          this.$router.push({ name: 'directorate-list' })
        })
    },
    normalizeForm() {
      const form = {...this.form}
      form.direction_category = form.direction_category.id
      form.birthday = dateFormatting(form.birthday, 'normal-to-iso-small')
      if (form.organization_json && form.organization_json.name) form.organization = form.organization_json.name
      else delete form.organization
      if (form.occupation === 1) delete form.position
      delete form.crop

      return form
    },
    onOccupationChange() {
      this.form.organization_json = ''
      this.form.position = ''
    },
    onCheckBirthdayDate() {
      this.$v.form.birthday.$touch()
      this.birthday_date = differenceInYears(new Date(), parse(this.form.birthday, 'dd.MM.yyyy', new Date()))
    },
    onEDUSearch(search, loading) {
      loading(true)
      this.searchEDU(loading, search, this)
    },
    searchEDU: debounce((loading, search, vm) => {
      getEduOrg(search).then(response => {
        vm.edu_options = response.data
        loading(false)
      })
    }, 350),
    onOrgSearch(search, loading) {
      loading(true)
      this.searchOrg(loading, search, this)
    },
    searchOrg: debounce((loading, search, vm) => {
      getOrg(search).then(response => {
        vm.org_options = response.data
        loading(false)
      })
    }, 350),
  }
}
</script>

